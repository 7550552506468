import React, { useState, useEffect, useRef } from "react";
import {
  useMappedState,
  useDispatch,
  ActionType,
  InitialPageInput,
} from "../store/Store";
import { useCallback } from "react";
import { AppState, LocalSettings } from "../configuration/AppConfig";
import { Button, Form, FormGroup, Input, Row, Col } from "reactstrap";
import { Styles } from "../configuration/AppConfig";
import { HelperFunctions } from "../utils/HelperFunctions";
import "./InitialPage.css";
import Api from "../utils/Api";
import Background from "./Background";
import LoadingScreen from "./LoadingScreen";
//@ts-ignore
import VisibilityAwareContent from "./VisibilityAwareContent";
import ScrollToTopButton from "./ScrollToTopButton";
import useScript from "../hooks/UseScript";
import userWindowsDimensions from "../hooks/UseWindowDimensions";
import TrustBox from "./Trustpilot";
import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { TextMaskPhone } from "./InputMasks";
import TagManager from "react-gtm-module";
import CheckIcon from "./InitialPageComponents/CheckIcon";
import ShieldIcon from "./InitialPageComponents/ShieldIcon";

declare global {
    interface Window {
        uetq:any;
    }
}

var callback: any;

export default function InitialPage() {
  const { height, width } = userWindowsDimensions();
  const location = useLocation();
  const isMobile = width != null && width < 960;

  const formStyles = {
    group: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      flexFlow: "wrap",
      margin: "auto",
      maxWidth: 1000,
    },
    item: {
      margin: 10,
      minWidth: 300,
      maxWidth: 300,
      textAlign: "left",
      "&::after": {
        content: "*",
      },
    },
    subtitle: {
      color: Styles.textColorBullet,
      margin: 10,
      // display: "flex",
      // alignItems: "center",
    },
    placeholder: {
      color: "rgba(0, 0, 0, 0.4)",
    },
    form: {
      margin: "auto",
      // paddingTop: isMobile ? 80 : 10,
      // height: isMobile ? "100vh" : "inherit",
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10,
      maxWidth: 500,
    },
    formSubmit: {
      textAlign: "center" as "center",
    },
    icon: {
      fill: Styles.iconFill,
      marginRight: 20,
    },
    submitButton: {
      backgroundColor: Styles.bgInitialButtonSubmit,
      borderColor: Styles.bgInitialButtonSubmit,
      color: Styles.textColorSubmit,
      borderRadius: 30,
      width: 240,
      fontSize: "1rem",
    },
    leftSidebar: {
      boxShadow:
        "0px 5px 4px rgba(0, 0, 0, 0.07), 0px 15px 35px rgba(50, 50, 93, 0.1)",
      backgroundColor: Styles.defaultColor,
    },
    logo: {
      height: 115.72,
      position: isMobile
        ? ("inherit" as "inherit")
        : ("absolute" as "absolute"),
      filter:
        isMobile && LocalSettings.useColorMobileLogo
          ? "brightness(0) invert(1)"
          : "",
      imageRendering:
        "-webkit-optimize-contrast" as "-webkit-optimize-contrast",
      top: isMobile ? 0 : LocalSettings.bgLogoInitialTopOffsetDesktop,
      margin: "auto",
      marginTop: isMobile ? 10 : -10,
      marginBottom: 10,
      left: isMobile ? 0 : 25,
    },
    logoPoweredBy: {
      top: 0,
      width: 220,
      imageRendering:
        "-webkit-optimize-contrast" as "-webkit-optimize-contrast",
      position: isMobile
        ? ("inherit" as "inherit")
        : ("absolute" as "absolute"),
      margin: "auto",
      right: 25,
    },
    prmiaryLabel: {
      color: Styles.textColorPrimary,
    },
    secondaryLabel: {
      color: Styles.textColorTitleSecondary,
    },
    footer: {
      backgroundColor: Styles.bgInitialFooterContainer,
      textAlign: "center" as "center",
      height: 114,
      marginTop: 40,
      marginBottom: 40,
    },
  };

  const dispatch = useDispatch();
  const data = useInitialPage();
  const [formStateCode, setFormStateCode] = useState(0);
  const [googleClientId, setGoogleClientId] = useState(null);

  let size: "sm" | "lg" | undefined;
  let labelStyle: any;
  let labelStyleLg: any;
  let submitButtonMargin: number | "auto" = "auto";
  let secondLabelMargin: number | "auto" = 5;
  let bulletsStyle: any;
  if (isMobile) {
    if (height != null && height >= 700) {
      size = "lg";
      labelStyle = { fontSize: "1.0rem" };
      labelStyleLg = { fontSize: "1.2rem" };
      submitButtonMargin = 5;
      secondLabelMargin = 5;
    } else {
      size = undefined;
      labelStyle = { fontSize: "0.875rem" };
      labelStyleLg = { fontSize: "1rem" };
      secondLabelMargin = 5;
    }
    bulletsStyle = { fontSize: "0.875rem" };
  } else {
    size = "lg";
    labelStyle = { fontSize: "1rem" };
    labelStyleLg = { fontSize: "1.2rem" };
    bulletsStyle = { fontSize: "1.25rem" };
  }

  let recaptchaRef = useRef<ReCAPTCHA>(null);

  const setReadyState = useCallback(
    () =>
      dispatch({ type: ActionType.CHANGE_APP_STATE, value: AppState.READY }),
    [dispatch]
  );
  const setLoadingState = useCallback(
    () =>
      dispatch({ type: ActionType.CHANGE_APP_STATE, value: AppState.LOADING }),
    [dispatch]
  );
  const setErrorState = useCallback(
    () =>
      dispatch({ type: ActionType.CHANGE_APP_STATE, value: AppState.ERROR }),
    [dispatch]
  );

  useScript(`${process.env.PUBLIC_URL}/assets/js/trustpilot.js`);

  window.removeEventListener("keydown", callback, true);
  callback = function (e: any) {
    handleUserKeyDown(e);
  };
  window.addEventListener("keydown", callback, true);

  useEffect(() => {
    ReactGA.initialize("UA-15983938-3");
    ReactGA.pageview(window.location.pathname + window.location.search);

    //MSD-4031
    //@ts-ignore
    ReactGA.ga((tracker: any) => {
      let clientId = tracker.get("clientId");
      setGoogleClientId(clientId);
    });

    const tagManagerArgs = {
      gtmId: "GTM-KW3ZJC7",
    };
    TagManager.initialize(tagManagerArgs);

    const welcomeTitle = LocalSettings.translation.initialPage.pageName;
    HelperFunctions.setPageTitle(
      `${welcomeTitle} - ${LocalSettings.companyName}`
    );

    setTimeout(() => {
      setReadyState();
    }, 1000);

    return () => {
      window.removeEventListener("keydown", handleUserKeyDown);
    };
  }, []);

  return <div>{renderApplication()}</div>;

  function useInitialPage(): InitialPageState {
    const { input, applicationState } = useMappedState((state) => state);
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) =>
        dispatch({
          type: ActionType.CHANGE_INPUT,
          value: e.target.value,
          propertyName: e.target.name,
        }),
      []
    );

    let queryParams = queryString.parse(location.search);

    //MSD-4016: Campaign details
    let utmSource = queryParams.utm_source || null;
    let utmMedium = queryParams.utm_medium || null;
    let utmCampaign = queryParams.utm_campaign || null;
    let utmTerm = queryParams.utm_term || null;
    let utmContent = queryParams.utm_content || null;
    let googleClickId = queryParams.gclid || null;
    let userId = queryParams.userId || null;
    let companyName = queryParams.companyName || null;
    let externalId = queryParams.externalId || null;

    // TODO: This is a temporary workaround for a certain userId, should be removed:
    if (userId === "0052M000009FEPZ=merrco") {
      window.location.href =
        "/welcome?userId=0052M000009FEPZ&companyName=merrco";
    }
    if (userId === "0052M000009Xhqi=Payfirma") {
      window.location.href =
        "/welcome?userId=0052M000009Xhqi&companyName=Payfirma";
    }

    return {
      applicationState: applicationState,
      input: {
        firstName: input.firstName,
        lastName: input.lastName,
        email: input.email,
        businessName: input.businessName,
        utmSource: utmSource,
        utmMedium: utmMedium,
        utmCampaign: utmCampaign,
        utmTerm: utmTerm,
        utmContent: utmContent,
        googleClickId: googleClickId,
        userId: userId,
        companyName: companyName,
        phoneNumber: input.phoneNumber,
        partnerExternalId: externalId,
        referenceSource: input.referenceSource,
      },
      handleChange,
    };
  }

  function renderApplication() {
    return (
      <div>
        <div
          style={{
            display:
              data.applicationState === AppState.LOADING ? "block" : "none",
          }}
        >
          <LoadingScreen />
        </div>
        <div
          style={{
            display:
              data.applicationState === AppState.READY ? "block" : "none",
          }}
        >
          {renderInitialPage()}
        </div>
      </div>
    );
  }

  // switch (data.applicationState) {
  //   case AppState.LOADING:
  //     return;
  //   case AppState.READY:
  //     return;
  //   default:
  //     throw new Error(`Unsupported state: ${data.applicationState}`);
  // }

  function executeRecaptcha() {
    recaptchaRef.current?.execute();
  }

  function handleUserKeyDown(event: KeyboardEvent): void {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();

      //Validate form:
      if (handleValidateForm()) {
        // Set application state to LOADING:
        setLoadingState();
        // Execute Google recaptcha
        executeRecaptcha();
        // handleSubmitInitialPage();
      } else {
        setFormStateCode(-1);
      }
    }
  }

  function submitApplication(recaptchaResponse: string) {
    const successCallback = (response: any) => {
      // Move to the next page:
      const userIdStr = input.userId ? `?userId=${input.userId}` : "";
      const languageStr =
        (userIdStr === "" ? "?" : "&") + `language=${LocalSettings.language}`;

      const companyNameStr = input.companyName
        ? `&companyName=${input.companyName}`
        : "";
      window.location.href = `/application/${response.data.applicationId}${userIdStr}${languageStr}${companyNameStr}`;
    };

    const errorCallback = (errorResponse: any) => {
      alert("Error!");
    };

    window.uetq=window.uetq || [];
    window.uetq.push('set', { 'pid': {
      'em': data.input.email,
      'ph': data.input.phoneNumber,
    }});
    window.uetq.push("event", "submit_lead_form", {});

    let input = {
      ...data.input,
      recaptchaResponse,
      language: LocalSettings.language,
      googleAnalyticsId: googleClientId,
      subType: "General",
      type: "Self",
    };

    Api.createApplication(input, successCallback, errorCallback);
  }

  function handleValidateForm() {
    let inputData = data.input;
    return (
      !HelperFunctions.isEmpty(inputData.firstName) &&
      !HelperFunctions.isEmpty(inputData.lastName) &&
      HelperFunctions.emailIsValid(inputData.email) &&
      !HelperFunctions.isEmpty(inputData.businessName) &&
      (HelperFunctions.isEmpty(inputData.phoneNumber) ||
        HelperFunctions.phoneIsValid(inputData.phoneNumber))
    );
  }

  function getSubmitButtonClass() {
    if (handleValidateForm()) {
      return "eligibility-button pulsate-bck";
    } else {
      return "eligibility-button";
    }
  }

  function handleInvalidEmail(email: string) {
    if (HelperFunctions.isEmpty(email)) {
      return LocalSettings.translation.initialPage.emailInvalid;
    } else if (!HelperFunctions.emailIsValid(email)) {
      return LocalSettings.translation.initialPage.emailInvalidFormat;
    } else {
      return null;
    }
  }

  function renderQuoteBlock() {
    if (isMobile) {
      return <Col className="align-items-center">{renderQuoteBlockBody()}</Col>;
    } else {
      return <Row className="align-items-center">{renderQuoteBlockBody()}</Row>;
    }
  }

  function renderQuoteBlockBody() {
    return [
      <Col className="col-auto" style={{ textAlign: "center", margin: 20 }}>
        <img
          src={LocalSettings.testimonialImage}
          alt="..."
          style={{ width: 110, height: 110, borderRadius: "50%" }}
        />
      </Col>,
      <Col>
        <blockquote className="blockquote text-center">
          <p
            className="mb-0"
            style={{
              textAlign: "left",
              fontWeight: 400,
              color: Styles.textColorQuote,
            }}
          >
            “{LocalSettings.translation.initialPage.testimonialText}“
          </p>
          <footer className="blockquote-footer" style={{ textAlign: "left" }}>
            {LocalSettings.translation.initialPage.testimonialAuthor}
          </footer>
        </blockquote>
      </Col>,
    ];
  }

  function renderFooter() {
    let sourcesArray: string[] = [
      "source_1.png",
      "source_2.png",
      "source_3.png",
      "source_4.png",
      "source_5.png",
      "source_6.png",
      "source_7.png",
      "source_8.png",
      "source_9.png",
      "source_10.jpg",
      "source_11.png",
      "source_12.jpg",
      "source_13.jpg",
    ];

    let widthsAndHeightsArray: {
      width: number;
      height: number;
      className: string;
    }[] = [
      {
        width: 54,
        height: 54,
        className: "do-not-show-1400px",
      },
      {
        width: 128,
        height: 51,
        className: "do-not-show-1400px",
      },
      {
        width: 56,
        height: 55,
        className: "do-not-show-1200px",
      },
      {
        width: 64,
        height: 57,
        className: "do-not-show-1200px",
      },
      {
        width: 104,
        height: 39,
        className: "do-not-show-1200px",
      },
      {
        width: 75,
        height: 58,
        className: "do-not-show-1000px",
      },
      {
        width: 169,
        height: 35,
        className: "do-not-show-1000px",
      },
      {
        width: 55,
        height: 55,
        className: "do-not-show-1000px",
      },
      {
        width: 92,
        height: 46,
        className: "do-not-show-1000px",
      },
      {
        width: 56,
        height: 54,
        className: "do-not-show-800px",
      },
      {
        width: 135,
        height: 135,
        className: "do-not-show-800px",
      },
      {
        width: 55,
        height: 56,
        className: "do-not-show-800px",
      },
      {
        width: 56,
        height: 56,
        className: "do-not-show-800px",
      },
    ];

    let currentWindowWidth = width || 0;

    return (
      <div>
        <div
          className="initial-page-animated initial-page-fadeInRight do-not-show-1000px"
          style={formStyles.footer}
        >
          <h5 style={{ fontSize: 13, color: "rgb(9, 1, 1)" }}>
            {LocalSettings.translation.initialPage.trustedByCanadians}
          </h5>
          <div
            style={{
              display: "flex",
              flexFlow: "row",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              margin: -40,
            }}
          >
            {sourcesArray.map((value: string, index: number) => {
              return (
                <div style={{ margin: 10 }}>
                  <img
                    className={widthsAndHeightsArray[index].className}
                    src={`${process.env.PUBLIC_URL}/assets/img/sources/${value}`}
                    width={widthsAndHeightsArray[index].width}
                    height={widthsAndHeightsArray[index].height}
                  ></img>
                </div>
              );
            })}
          </div>
        </div>
        <div className="do-not-show-1000px">
          <VisibilityAwareContent
            partialVisibility={false}
            animationClassName={
              "initial-page-animated initial-page-fadeInRight"
            }
          >
            <div
              style={{
                marginLeft: currentWindowWidth > 1200 ? 200 : 100,
                marginRight: currentWindowWidth > 1200 ? 200 : 100,
                marginTop: 30,
                marginBottom: 30,
                display: "flex",
                flexWrap: "wrap",
                flexFlow: "row",
              }}
            >
              <div style={{ margin: 30 }}>
                <img src={LocalSettings.companyImage}></img>
              </div>
              <div>
                <div
                  style={{
                    margin: 30,
                    color: "#8898aa",
                  }}
                >
                  <p
                    style={{
                      fontSize: currentWindowWidth > 1200 ? 18 : 16,
                      color: Styles.textColorDescription,
                    }}
                  >
                    {
                      LocalSettings.translation.initialPage
                        .bottomTextFirstParagraph
                    }
                  </p>
                  <p
                    style={{
                      fontSize: currentWindowWidth > 1200 ? 18 : 16,
                      color: Styles.textColorDescription,
                    }}
                  >
                    {
                      LocalSettings.translation.initialPage
                        .bottomTextSecondParagraph
                    }
                  </p>
                </div>
              </div>
            </div>
          </VisibilityAwareContent>
        </div>
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            background: "rgba(255,255,255,1)",
            borderStyle: "solid none none none",
            borderWidth: 1,
            borderColor: "#d4d9db",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 0,
            borderRadius: 0,
            width: "100%",
            height: 90,
            position: "relative",
          }}
        >
          <img
            src={LocalSettings.logoBottomUrl}
            style={{
              imageRendering: "-webkit-optimize-contrast",
              width: currentWindowWidth > 500 ? 170 : 150,
              marginLeft: currentWindowWidth > 1200 ? 220 : 10,
            }}
          ></img>
          <div
            style={{
              marginRight: currentWindowWidth > 1200 ? 220 : 10,
              fontSize: currentWindowWidth > 800 ? 16 : 12,
              width: currentWindowWidth > 500 ? "auto" : 190,
            }}
          >
            <div
              style={{
                display: LocalSettings.formattedAddressVisible
                  ? "block"
                  : "none",
              }}
            >
              {LocalSettings.translation.initialPage.address}:{" "}
              <a
                style={{ color: Styles.textColorPrimary }}
                href={LocalSettings.googleMapsUrl}
                target="_blank"
              >
                {LocalSettings.formattedAddress}
              </a>
            </div>
            <div
              style={{
                display: LocalSettings.useEmail ? "block" : "none",
              }}
            >
              {LocalSettings.translation.initialPage.emailUs}:{" "}
              <a
                style={{ color: Styles.textColorPrimary }}
                href={`mailto:${LocalSettings.email}`}
                target="_blank"
              >
                {LocalSettings.email}
              </a>
            </div>
            <div
              style={{
                display: LocalSettings.useEmail ? "none" : "block",
              }}
            >
              {LocalSettings.translation.initialPage.callUs}:{" "}
              <a
                style={{ color: Styles.textColorPrimary }}
                href={`tel:${LocalSettings.phone}`}
              >
                {LocalSettings.formattedPhone}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderInitialPage() {
    const useLandingPage = LocalSettings.useLandingPage;
    const inputData = data.input;
    const backgrondImageName =
      width !== null && width < 500 ? "initial" : "initial";
    return (
      <Background
        hideMainLogo={true}
        image={backgrondImageName}
        useTemplate={false}
        useFooter={true}
        useSvg={true}
      >
        <div>
          <img
            src={LocalSettings.logoUrl}
            style={{
              ...formStyles.logo,
              display: isMobile ? "none" : "block",
            }}
            alt="..."
          />
          <img
            src={LocalSettings.logoPoweredByUrl}
            style={{
              ...formStyles.logoPoweredBy,
              display: isMobile
                ? "none"
                : LocalSettings.logoPoweredByVisible
                ? "inherit"
                : "none",
            }}
            alt="..."
          ></img>
        </div>
        <div>
          <div className="prequalify-container">
            <div style={{ 
              marginLeft: "auto", 
              marginRight: "auto", 
              }}>
              <div
                className="left-sidebar initial-page-animated initial-page-fadeInLeft"
                style={formStyles.leftSidebar}
              >
                <img
                  src={LocalSettings.logoMobileUrl}
                  style={{
                    ...formStyles.logo,
                    display: isMobile ? "block" : "none",
                  }}
                  alt="..."
                />
                <div style={formStyles.form}>
                  <div style={{ textAlign: "center" }}>
                    <div></div>
                    <h1
                      style={{
                        fontStyle: "normal",
                        lineHeight: "33px",
                        ...labelStyleLg,
                        ...formStyles.prmiaryLabel,
                      }}
                    >
                      {LocalSettings.translation.initialPage.greetingPrimary}
                    </h1>
                    {/* <p
                      style={{
                        ...labelStyle,
                        marginTop: secondLabelMargin,
                        marginBottom: secondLabelMargin,
                        ...formStyles.secondaryLabel,
                      }}
                      className="lead display-none-for-300-or-less"
                    >
                      {LocalSettings.translation.initialPage.greetingSecondary}
                    </p> */}
                  </div>
                  <Form>
                    <FormGroup>
                      <Input
                        placeholder={
                          LocalSettings.translation.initialPage.firstName
                        }
                        bsSize={size}
                        type="text"
                        name="firstName"
                        className={HelperFunctions.assignInputClass(
                          inputData.firstName
                        )}
                        value={inputData.firstName}
                        onChange={(e) => data.handleChange(e)}
                        invalid={
                          HelperFunctions.isEmpty(inputData.firstName) &&
                          formStateCode === -1
                        }
                      ></Input>
                      <div className="invalid-feedback text-focus-in feedback-relative">
                        {LocalSettings.translation.initialPage.firstNameInvalid}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        placeholder={
                          LocalSettings.translation.initialPage.lastName
                        }
                        bsSize={size}
                        type="text"
                        name="lastName"
                        autoComplete="family-name"
                        className={HelperFunctions.assignInputClass(
                          inputData.lastName
                        )}
                        value={inputData.lastName}
                        onChange={(e) => data.handleChange(e)}
                        invalid={
                          HelperFunctions.isEmpty(inputData.lastName) &&
                          formStateCode === -1
                        }
                      ></Input>
                      <div className="invalid-feedback text-focus-in feedback-relative">
                        {LocalSettings.translation.initialPage.lastNameInvalid}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        placeholder={
                          LocalSettings.translation.initialPage.email
                        }
                        bsSize={size}
                        type="email"
                        name="email"
                        className={HelperFunctions.assignInputClass(
                          inputData.email
                        )}
                        value={inputData.email}
                        onChange={(e) => data.handleChange(e)}
                        invalid={
                          (HelperFunctions.isEmpty(inputData.email) &&
                            formStateCode === -1) ||
                          (!HelperFunctions.isEmpty(inputData.email) &&
                            !HelperFunctions.emailIsValid(inputData.email) &&
                            formStateCode === -1)
                        }
                      ></Input>
                      <div className="invalid-feedback text-focus-in feedback-relative">
                        {handleInvalidEmail(inputData.email)}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        placeholder={
                          LocalSettings.translation.initialPage.businessName
                        }
                        bsSize={size}
                        type="text"
                        name="businessName"
                        className={HelperFunctions.assignInputClass(
                          inputData.businessName
                        )}
                        value={inputData.businessName}
                        onChange={(e) => data.handleChange(e)}
                        invalid={
                          HelperFunctions.isEmpty(inputData.businessName) &&
                          formStateCode === -1
                        }
                      ></Input>
                      <div className="invalid-feedback text-focus-in feedback-relative">
                        {
                          LocalSettings.translation.initialPage
                            .businessNameInvalid
                        }
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        placeholder={
                          LocalSettings.translation.initialPage.mobilePhone
                        }
                        bsSize={size}
                        type="text"
                        name="phoneNumber"
                        className={HelperFunctions.assignInputClass(
                          inputData.phoneNumber
                        )}
                        value={inputData.phoneNumber}
                        tag={TextMaskPhone}
                        autoComplete="tel"
                        onChange={(e) => data.handleChange(e)}
                        invalid={
                          !HelperFunctions.isEmpty(inputData.phoneNumber) &&
                          !HelperFunctions.phoneIsValid(
                            inputData.phoneNumber
                          ) &&
                          formStateCode === -1
                        }
                      ></Input>
                      <div className="invalid-feedback text-focus-in feedback-relative">
                        {
                          LocalSettings.translation.initialPage
                            .mobilePhoneInvalid
                        }
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        bsSize={size}
                        type="select"
                        name="referenceSource"
                        className={HelperFunctions.assignInputClass(
                          inputData.referenceSource || ""
                        )}
                        value={inputData.referenceSource || ""}
                        onChange={(e) => data.handleChange(e)}
                        style={{color: "#adb5bd"}}
                      >
                        <option value="" disabled>
                         {LocalSettings.translation.initialPage.referenceSource}
                        </option>
                        <option value="Google/Bing">{LocalSettings.translation.initialPage.referenceSourceDropdown.google}</option>
                        <option value="Direct Mail">{LocalSettings.translation.initialPage.referenceSourceDropdown.direct}</option>
                        <option value="TV Commercial">{LocalSettings.translation.initialPage.referenceSourceDropdown.tv}</option>
                        <option value="Facebook/Instagram Ad">{LocalSettings.translation.initialPage.referenceSourceDropdown.facebook}</option>
                        <option value="Internet Banner Ad">{LocalSettings.translation.initialPage.referenceSourceDropdown.banner}</option>
                        <option value="YouTube Ad">{LocalSettings.translation.initialPage.referenceSourceDropdown.youtube}</option>
                        <option value="Friend/Family">{LocalSettings.translation.initialPage.referenceSourceDropdown.friend}</option>
                        <option value="Third Party Broker">{LocalSettings.translation.initialPage.referenceSourceDropdown.third}</option>
                        <option value="Other">{LocalSettings.translation.initialPage.referenceSourceDropdown.other}</option>
                      </Input>
                    </FormGroup>
                    <div style={formStyles.formSubmit}>
                      <Button
                        size="lg"
                        className={getSubmitButtonClass()}
                        onClick={() => {
                          //Validate form:
                          if (handleValidateForm()) {
                            setLoadingState();
                            executeRecaptcha();
                            // handleSubmitInitialPage();
                          } else {
                            setFormStateCode(-1);
                          }
                        }}
                        style={{
                          ...formStyles.submitButton,
                          margin: submitButtonMargin,
                        }}
                      >
                        {LocalSettings.translation.initialPage.submit}
                      </Button>
                    </div>
                  </Form>
                  <div style={{ display: "none" }}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey="6LdpauUUAAAAAHtW_YyUIlSR09BixNeFesguNXUN"
                      onChange={(response: string | null) => {
                        if (response != null) submitApplication(response);
                        else alert("An error has occured!");
                      }}
                      onExpired={() => {
                        recaptchaRef.current?.execute();
                      }}
                    />
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <img
                    src={LocalSettings.logoPoweredByUrl}
                    style={{
                      ...formStyles.logoPoweredBy,
                      display: isMobile
                        ? LocalSettings.logoPoweredByVisible
                          ? "inherit"
                          : "none"
                        : "none",
                    }}
                    alt="..."
                  />
                </div>
              </div>
              <div
                className="hidden-for-mobile initial-page-animated initial-page-fadeInLeft"
                style={{
                  marginTop: useLandingPage ? 30 : (height || 0) > 780 ? 10 : 0,
                  paddingBottom: useLandingPage ? 0 : 10,
                  textAlign: "center",
                }}
              >
                <Row form>
                  <Col md={12}>
                    <div
                      className="pre-qualify-protected"
                      style={{
                        marginTop: 0,
                        textAlign: "center",
                      }}
                    >
                      <ShieldIcon></ShieldIcon>
                      <span className="lead">
                        {LocalSettings.translation.initialPage.protectedBy}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div
              style={{
                display: useLandingPage ? "block" : "none",
              }}
              className="right-sidebar initial-page-animated initial-page-fadeInRight"
            >
              <div className="check-block-general">
                {LocalSettings.translation.initialPage.items.map((item) => (
                  <VisibilityAwareContent
                    partialVisibility={false}
                    animationClassName={
                      "initial-page-animated initial-page-fadeInRight"
                    }
                  >
                    <Row style={{ marginLeft: "auto", marginRight: "auto" }}>
                      <Col>
                        <div
                          style={{ ...formStyles.subtitle, ...bulletsStyle }}
                          className="checklist"
                        >
                          <CheckIcon></CheckIcon>
                          <div>{item}</div>
                        </div>
                      </Col>
                    </Row>
                  </VisibilityAwareContent>
                ))}
              </div>
              <VisibilityAwareContent
                animationClassName={
                  "initial-page-animated initial-page-fadeInRight"
                }
              >
                <div className="quote-block">{renderQuoteBlock()}</div>
              </VisibilityAwareContent>
              <VisibilityAwareContent
                animationClassName={
                  "initial-page-animated initial-page-fadeInRight"
                }
              >
                <div style={{ marginBottom: isMobile ? 20 : 0 }}>
                  <Row form>
                    <Col md={12}>
                      <TrustBox />
                    </Col>
                  </Row>
                </div>
              </VisibilityAwareContent>
            </div>
          </div>
        </div>
        <div
          style={{ width: "100%", display: useLandingPage ? "block" : "none" }}
        >
          {renderFooter()}
        </div>
        <div style={{ display: width && width > 500 ? "block" : "none" }}>
          <ScrollToTopButton scrollStepInPx={50} delayInMs={16.66} />
        </div>
      </Background>
    );
  }
}

type InitialPageState = {
  applicationState: AppState;
  input: InitialPageInput;
  handleChange: Function;
};
